import React, { Component } from "react";
import "./style.scss";
/**
 * Loader : The Common Re-usable Loader across website.
 * @return {JSX.Element} The JSX Code for Loader
 */
export class CommonPageLoaderClass extends Component {
  render() {
    let { isSpinningLoader = false } = this.props;
    return !isSpinningLoader ? (

      <div className="loader">
        <div className="loader-circle" />
      </div>

    ) : (

      // <div className="lds-spinner">
      //   <div />
      //   <div />
      //   <div />
      //   <div />
      //   <div />
      //   <div />
      //   <div />
      //   <div />
      //   <div />
      //   <div />
      //   <div />
      //   <div />
      // </div>

      <div>
        <img alt="" src="https://cdn.shopify.com/s/files/1/0616/7222/6982/files/VB-Dace-Logo-Loading-400-X-400_1.gif?v=1678710067" width='100'  />
      </div>
    );
  }
}

export const CommonPageLoader = CommonPageLoaderClass;
