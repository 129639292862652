import React, { Component, Suspense } from "react";
import { Route, Router, Redirect, withRouter } from "react-router-dom";
import CodeSplitter from "utils/CodeSplitter";
import { NotificationContainer } from "react-notifications";
import Routers from "./routes";
import * as Layout from "layout";
import { history } from "../redux/store/store";
import { CommonPageLoader } from "../component/common/CommonPageLoader"
import { Helmet } from 'react-helmet';
import { MetaData } from '../utils/MetaData'

export class RoutesClass extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const { location } = this.props;
    const pageLocation = location?.pathname;
    const metaItem = MetaData.find((item) => item.path === pageLocation);
    // console.log(MetaData[0]?.path, 'MetaData', pageLocation);
    // console.log(MetaData[0]?.path == pageLocation, 'MetaData2')

    return (
      <React.Fragment>

        {MetaData?.map((item) => (
          <Helmet key={item.id}>
            <meta charSet="utf-8" />
            <title>{metaItem ? metaItem?.title : 'VBDace'}</title>
            <meta name="description" content={metaItem ? metaItem?.desp : 'Your one-stop destination for high-quality cookware, bakeware, utensils, and more'} />
          </Helmet>
        ))}


        {/* {<p>Current Path: {location?.pathname}</p>} */}
        <Router history={history}>
          <Suspense fallback={<span style={{ top: "0", left: "0" }} className="h-100 text-danger w-100 position-fixed d-flex flex-wrap justify-content-center align-content-center"><span className="d-block"><CommonPageLoader isSpinningLoader={true} /></span></span>}>
            {Routers.map(
              ({
                component,
                name,
                componentPath = "",
                redirect,
                path,
                exact = false,
                auth = true,
                childrens = [],
              }) => {
                // console.log(component, "componentcomponent");

                if (childrens.length > 0) {
                  return (
                    <Route
                      path={path}
                      exact={exact}
                      key={path}
                      render={(props) => {
                        if (redirect) {
                          if (props.location.pathname == path) {
                            props.history.push(redirect);
                          }
                        }
                        // console.log(component, props.location, "componentcomponent");
                        const LayoutComponent = Layout[component];

                        return (
                          <LayoutComponent {...props}>
                            <Suspense fallback={"Loading"}>
                              {childrens.map(
                                ({
                                  componentPath: childComponentPath,
                                  name = "",
                                  path: childrenPath,
                                  exact = false,
                                  auth = true,
                                  redirect: redirectPath
                                }) => {
                                  if (redirectPath) {
                                    if (props.location.pathname == path) {
                                      return props.history.push(redirectPath);
                                    }
                                  }
                                  CodeSplitter.addComponent(
                                    childComponentPath,
                                    name
                                  );

                                  return (
                                    <Route
                                      path={path + childrenPath}
                                      exact={exact}
                                      key={path + childrenPath}
                                      render={(props) => {
                                        let PageComponent =
                                          CodeSplitter.getComponent(name);

                                        return <PageComponent {...props} />;
                                      }}
                                    />
                                  );
                                }
                              )}
                            </Suspense>
                          </LayoutComponent>
                        );
                      }}
                      history={history}
                    />
                  );
                }

                CodeSplitter.addComponent(componentPath, name);

                return (
                  <Route
                    path={path}
                    exact={exact}
                    key={component || 2322}
                    render={(props) => {
                      // console.log(props.location, "componentcomponent1");
                      if (component) {
                        let PageComponent = CodeSplitter.getComponent(name);
                        return <PageComponent {...props} />;
                      }

                      if (redirect) {
                        if (props.location.pathname == path) {
                          return <Redirect to={redirect} />;
                        }
                      }

                      return <div></div>;
                    }}
                  />
                );
              }
            )}

            <NotificationContainer />
          </Suspense>
        </Router>
      </React.Fragment>
    );
  }
}

export const Routes = withRouter(RoutesClass);
