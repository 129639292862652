//Users
export const CREATE_CUSTOMER = "CREATE_CUSTOMER";

//Products
export const GET_CATEGORIES = "GET_CATEGORIES";

//Add cart
export const ADD_CART = "ADD_CART";

//user data store
export const USER_DATA = "USER_DATA";

//update cartData
export const UPDATE_CART = "UPDATE_CART";

export const SINGLE_BLOG = "SINGLE_BLOG";

export const SINGLE_MY_ORDER = "SINGLE_MY_ORDER"
